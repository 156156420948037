import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Tabs} from 'antd';
import * as AppActions from '../../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import FundraisingBanner from './FundraisingBanner';
import FundraisingProductRoot from './FundraisingProductRoot';
import FundraisingProductItem from './FundraisingProductItem';
import FundraisingUpdateList from './FundraisingUpdateList';

export default function FundraisingProductGroupDetail(props) {
  const {product} = props;
  const [groupProducts, setGroupProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [dimension] = useOutlet('dimension');

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const products = await JStorage.fetchAllDocuments(
          'product',
          {
            group_root_product: product.id,
          },
          null,
          null,
          {anonymous: true},
        );
        setGroupProducts(products);
        if (products.length > 0) {
          setSelectedProduct(products[0]);
        }
      } catch (ex) {
        console.warn('ProductGroupDetail.useEffect ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [product]);

  if (!selectedProduct) {
    return null;
  }

  return (
    <Wrapper>
      <FundraisingBanner product={product} />
      <div className="content">
        <Tabs
          defaultActiveKey="1"
          size="large"
          tabBarStyle={{margin: '0 10px'}}>
          <Tabs.TabPane tab="商品介紹" key="1">
            <div className="tab-inner">
              <div className="main">
                <FundraisingProductRoot product={product} />
              </div>
              <div className="side fundraising-children-1">
                <h3>贊助選項</h3>
                <div className="items-container">
                  {groupProducts.map((groupProduct) => (
                    <FundraisingProductItem
                      key={groupProduct.id}
                      product={groupProduct}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="募資歷程" key="2">
            <div className="tab-inner">
              <div className="main">
                <FundraisingUpdateList product={product} />
              </div>
              <div className="side fundraising-children-2">
                <h3>贊助選項</h3>
                <div className="items-container">
                  {groupProducts.map((groupProduct) => (
                    <FundraisingProductItem
                      key={groupProduct.id}
                      product={groupProduct}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: 1000px;
    width: 100%;

    margin: 0 auto;
    padding: 40px 10px;
  }

  & .tab-inner {
    display: flex;

    & > .main {
      flex: 1;
      padding: 15px;
    }

    & > .side {
      max-width: 260px;
      width: 100%;
      padding: 15px;

      & > .items-container {
      }
    }
  }

  @media screen and (max-width: 768px) {
    & .tab-inner {
      flex-direction: column;

      & > .side {
        max-width: 100%;
        text-align: center;

        & > .items-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }
`;
