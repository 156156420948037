import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Button} from 'antd';
import {DollarCircle} from '@styled-icons/boxicons-regular/DollarCircle';
import {PersonFill} from '@styled-icons/bootstrap/PersonFill';
import {Alarm} from '@styled-icons/boxicons-regular/Alarm';
import {Facebook} from '@styled-icons/fa-brands/Facebook';
import {Instagram} from '@styled-icons/fa-brands/Instagram';
import {Share} from '@styled-icons/boxicons-regular/Share';
import {formatCurrencyDisplay} from '../../../Utils/TextUtil';

export default function FundraisingBanner(props) {
  const [dimension] = useOutlet('dimension');
  const {product} = props;
  const [selectedSlideVideo, setSelectedSlideVideo] = React.useState(
    product.fundraising_promo_video,
  );
  const [selectedSlideImg, setSelectedSlideImg] = React.useState(
    product.selectedSlideVideo
      ? null
      : Array.isArray(product.fundraising_images) &&
        product.fundraising_images.length > 0
      ? product.fundraising_images[0]
      : null,
  );

  return (
    <Wrapper>
      <div className="row-container">
        <div className="promo">
          {selectedSlideVideo ? (
            <iframe
              width="100%"
              height="100%"
              title="promo-video"
              id="promo-video-id"
              src={selectedSlideVideo}
              allowFullScreen
              frameBorder="0"
            />
          ) : selectedSlideImg ? (
            <img
              alt="product-slide"
              src={selectedSlideImg}
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          ) : (
            <div />
          )}
        </div>
        <div className="info">
          <Container className="col">
            <IconLabel>
              <DollarCircle size={24} color="#ffffff" />
              <label>累積總金額</label>
            </IconLabel>
            <Text className="large right">
              {formatCurrencyDisplay(product.fundraising_total) || 0} 元
            </Text>
          </Container>

          <Container className="col">
            <Text className="small right">
              目標金額：{formatCurrencyDisplay(product.fundraising_target) || 0}{' '}
              元
            </Text>
          </Container>

          <Container className="row">
            <IconLabel>
              <PersonFill size={24} color="#ffffff" />
              <label>累積人數</label>
            </IconLabel>
            <Text className="large right">
              {formatCurrencyDisplay(product.fundraising_count) || 0} 人
            </Text>
          </Container>

          <Container className="row">
            <IconLabel>
              <Alarm size={24} color="#ffffff" />
              <label>剩餘天數</label>
            </IconLabel>
            <Text className="medium right">
              {new Date().getTime() >
              new Date(product.fundraising_start_date).getTime() +
                product.fundraising_duration * 86400000
                ? `募資結束`
                : new Date().getTime() -
                    new Date(product.fundraising_start_date).getTime() >=
                  0
                ? `${Math.ceil(
                    (new Date(product.fundraising_start_date).getTime() +
                      product.fundraising_duration * 86400000 -
                      new Date().getTime()) /
                      86400000,
                  )} 天 `
                : `即將開始`}
            </Text>
          </Container>

          <Container css="flex: 1;" />

          <BuyButton
            onClick={() => {
              let element = document.querySelector('.fundraising-children-1');
              let boundingClientRect = element.getBoundingClientRect();

              if (boundingClientRect.top === 0) {
                element = document.querySelector('.fundraising-children-2');
                boundingClientRect = element.getBoundingClientRect();
              }

              window.scrollTo({
                top: boundingClientRect.top - 60,
                behavior: 'smooth',
              });
            }}>
            購買支持
          </BuyButton>
        </div>
      </div>
      <div className="col-container">
        <div className="gallery">
          {product.fundraising_promo_video && (
            <Thumbnail
              alt="product-thumbnail-video"
              src={product.fundraising_promo_video_thumbnail}
              onClick={() =>
                setSelectedSlideVideo(product.fundraising_promo_video)
              }
            />
          )}

          {Array.isArray(product.images) &&
            product.fundraising_images.length > 0 &&
            product.fundraising_images.map((image, index) => (
              <Thumbnail
                key={index}
                alt="product-thumbnail"
                src={image}
                onClick={() => {
                  setSelectedSlideVideo(null);
                  setSelectedSlideImg(image);
                }}
              />
            ))}
        </div>
        <div className="title">
          <h2>{product.name}</h2>
          <p>{product.fundraising_description}</p>
        </div>
        <div className="info">
          <Container className="col">
            <IconLabel black justify="center">
              <DollarCircle size={20} color="#d0d0d0" />
              <label>累積總金額</label>
            </IconLabel>
            <Text className="large center black">
              {formatCurrencyDisplay(product.fundraising_total) || 0} 元
            </Text>
          </Container>

          <Container className="col">
            <Text className="small center black">
              目標金額：{formatCurrencyDisplay(product.fundraising_target) || 0}{' '}
              元
            </Text>
          </Container>

          <Container className="row">
            <Container className="col" css="align-items: center;">
              <IconLabel black justify="center">
                <PersonFill size={20} color="#d0d0d0" />
                <label>累積人數</label>
              </IconLabel>
              <Text className="medium center black">
                {formatCurrencyDisplay(product.fundraising_count) || 0} 人
              </Text>
            </Container>

            <Container className="col">
              <IconLabel black justify="center">
                <Alarm size={20} color="#d0d0d0" />
                <label>剩餘天數</label>
              </IconLabel>
              <Text className="medium center black">
                {new Date().getTime() >
                new Date(product.fundraising_start_date).getTime() +
                  product.fundraising_duration * 86400000
                  ? `募資結束`
                  : new Date().getTime() -
                      new Date(product.fundraising_start_date).getTime() >=
                    0
                  ? `${Math.ceil(
                      (new Date(product.fundraising_start_date).getTime() +
                        product.fundraising_duration * 86400000 -
                        new Date().getTime()) /
                        86400000,
                    )} 天 `
                  : `即將開始`}
              </Text>
            </Container>
          </Container>
        </div>
        <div className="share">
          <Button
            className="icon-button"
            shape="circle"
            ghost
            style={{
              marginRight: 8,
            }}>
            <Share size={26} color="#808080" />
          </Button>

          <Button
            className="icon-button"
            shape="circle"
            ghost
            style={{
              marginRight: 8,
            }}>
            <Facebook size={26} color="#808080" />
          </Button>

          <Button className="icon-button" shape="circle" ghost>
            <Instagram size={26} color="#808080" />
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px;
  background-color: #f5f5f5;

  & > .row-container {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;

    display: flex;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover {
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.16);
    }

    & > .promo {
      background-color: #d0d0d0;
      // 16:9 (42)
      width: 688px;
      height: 387px;
    }

    & > .info {
      background-color: #3c3c3c;
      flex: 1;
      padding: 15px;

      display: flex;
      flex-direction: column;
    }
  }

  & > .col-container {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;

    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover {
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.16);
    }

    & > .gallery {
      background-color: #ffffff;
      border-bottom: 1px solid #d0d0d0;

      display: flex;
      flex-wrap: wrap;
    }

    & > .title {
      background-color: #ffffff;
      width: 100%;
      padding: 15px 20px;
    }

    & > .info {
      display: none;

      background-color: #ffffff;
      width: 100%;
      padding: 15px;
    }

    & > .share {
      background-color: #ffffff;
      width: 100%;
      padding: 15px 20px;

      display: flex;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 1200px) {
    & > .row-container {
      & > .promo {
        // 16:9 (40)
        width: 640px;
        height: 360px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    & > .row-container {
      & > .promo {
        // 16:9 (36)
        width: 560px;
        height: 315px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    & > .row-container {
      & > .promo {
        // 16:9 (34)
        width: 544px;
        height: 306px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    & > .row-container {
      & > .promo {
        // 16:9 (32)
        width: 512px;
        height: 288px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
    & > .row-container {
      max-width: 560px;

      & > .promo {
        // 16:9 (36)
        width: 560px;
        height: 315px;
      }

      & > .info {
        display: none;
      }
    }

    & > .col-container {
      max-width: 560px;

      & > .info {
        display: block;
      }
    }
  }
`;

const IconLabel = styled.div`
  display: flex;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};

  & > label {
    margin-left: 6px;
    color: ${(props) => (props.black ? '#000000' : '#ffffff')};
    font-size: 1rem;
  }

  @media screen and (max-width: 1000px) {
    & > label {
      font-size: 0.8rem;
    }
  }
`;

const Container = styled.div`
  margin: 5px 0px;
  width: 100%;

  &.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
  }

  ${(props) => props.css || ''}
`;

const Text = styled.div`
  &.large {
    font-size: 2rem;
    color: #ffffff;
  }

  &.medium {
    font-size: 1.2rem;
    color: #ffffff;
  }

  &.small {
    font-size: 1rem;
    color: #ffffff;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.black {
    color: #000000;
  }

  @media screen and (max-width: 1000px) {
    &.large {
      font-size: 1.6rem;
    }

    &.medium {
      font-size: 0.8rem;
    }

    &.small {
      font-size: 0.6rem;
    }
  }
`;

const BuyButton = styled.button`
  width: 100%;
  padding: 8px;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  background-color: var(--primaryColor);
  border: none;
  border-radius: 2px;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 45px;
  objectfit: cover;
  margin: 10px;
  cursor: pointer;
`;
