import React from 'react';
import DefaultProductDetail from './default';
import ProductGroupDetail from './group';
import FundraisingProductGroupDetail from './fundraising';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

function getProductIdFromLocation(location) {
  const params = qs.parse(location.search);
  return params.id;
}

function ProductDetail(props) {
  const {
    pageContext: {prebuiltProduct = null},
  } = props;
  const id = getProductIdFromLocation(props.location);
  const [product, setProduct] = React.useState(prebuiltProduct);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setProduct(
          await JStorage.fetchOneDocument('product', {id}, null, {
            anonymous: true,
          }),
        );
      } catch (ex) {
        console.warn('ProductDetail.useEffect ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (!prebuiltProduct && id) {
      fetchData();
    }
  }, [id, prebuiltProduct]);

  if (!prebuiltProduct && !product) {
    return <div style={{minHeight: 'calc(100vh)'}} />;
  }

  if (product.type === 'group_root') {
    return <FundraisingProductGroupDetail {...props} product={product} />;
    // return <ProductGroupDetail {...props} product={product} />;
  } else {
    return <DefaultProductDetail {...props} product={product} />;
  }
}

export default withPageEntry(ProductDetail);
