import React from 'react';
import styled from 'styled-components';
import {mapLineBreak} from '../../../Utils/TextUtil';

export default function FundraisingUpdateList(props) {
  const {product} = props;
  return (
    <Wrapper>
      {(product.updates || []).map((item, index) => (
        <div className="update-item" key={index}>
          <div className="top-container">
            <h4>{item.title}</h4>
          </div>
          <div className="main-container">
            <div className="date">{item.date}</div>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: mapLineBreak(item.content),
              }}
            />
            {(item.images || []).map((obj, idx) => {
              return <img src={obj?.url} key={idx} alt="update info" />;
            })}
          </div>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .update-item {
    margin: 20px 0px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover {
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.16);
    }

    & > .top-container {
      border-bottom: 1px solid #f3f3f3;
      border-left: 5px solid var(--primaryColor);
      padding: 15px 20px;

      & > h4 {
        margin: 0px;
        font-size: 1.2rem;
        font-weight: 400;
      }
    }

    & > .main-container {
      padding: 20px;

      & > .date {
        margin-bottom: 8px;
      }

      & > .text {
        margin-bottom: 10px;
        font-weight: 300;
      }

      & > img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
