import React from 'react';
import styled from 'styled-components';
import {Button, message} from 'antd';
import {DollarCircle} from '@styled-icons/boxicons-regular/DollarCircle';
import {PersonFill} from '@styled-icons/bootstrap/PersonFill';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import tracker from 'rev.sdk.js/Utils/Tracker';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {mapLineBreak, formatCurrencyDisplay} from '../../../Utils/TextUtil';
import * as AppActions from '../../../AppActions';
import constants from '../../../../data.json';

export default function FundraisingProductItem(props) {
  const {product} = props;
  const showLoginModal = useOutletSetter('login-modal');
  const [user] = useOutlet('user');
  const [currItemConfig, setCurrItemConfig] = React.useState({
    qty: 1,
    variants: [],
  });

  async function addToCart() {
    try {
      AppActions.setLoading(true);
      await Cart.addToCart(product.id, currItemConfig);
      tracker.addToCart({product, quantity: currItemConfig.qty});
      message.success('成功');
    } catch (ex) {
      console.warn(ex);
      if (ex.response.error === 'item_length_limit') {
        message.error('超過購物車購買上限，請分批購買');
      } else {
        message.error('發生錯誤, 請稍後再重新嘗試');
      }
    } finally {
      AppActions.setLoading(false);
    }
  }

  return (
    <Wrapper>
      <div className="top-container">
        <h4>{formatCurrencyDisplay(product.price)} 元</h4>
      </div>
      <div className="main-container">
        <h4>{product.name}</h4>
        {Array.isArray(product.images) && product.images.length > 0 && (
          <img alt="product" src={product.images[0].expected_url} />
        )}

        <div className="container">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: mapLineBreak(product.intro),
            }}
          />
        </div>

        <div className="container">
          <div className="icon-label">
            <DollarCircle size={16} color={constants.THEME_COLOR} />
            <label>累積金額：{product.fundraising_total || 0} 元</label>
          </div>
          <div className="icon-label">
            <PersonFill size={16} color={constants.THEME_COLOR} />
            <label>累積人數：{product.fundraising_count || 0} 人</label>
          </div>
        </div>

        <div className="buttons">
          <Button
            style={{marginRight: 5}}
            onClick={async () => {
              if (!user) {
                showLoginModal(true);
                return;
              }

              await addToCart();
            }}>
            加入購物車
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              if (!user) {
                showLoginModal(true);
                return;
              }

              await addToCart();
              AppActions.navigate('/checkout', {loading: true});
            }}>
            立即購買
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 230px;
  width: 100%;
  margin: 20px 0px 25px 0px;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  &:hover {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.16);
  }

  & > .top-container {
    border-bottom: 1px solid #f3f3f3;
    border-left: 5px solid var(--primaryColor);
    padding: 15px;

    & > h4 {
      margin: 0px;
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  & > .main-container {
    padding: 10px;

    & > h4 {
      margin-bottom: 8px;
      font-size: 1rem;
      font-weight: 400;
    }

    & > img {
      width: 208px;
      height: 117px;

      margin-bottom: 10px;
    }

    & > div.container {
      border-top: 1px solid #f3f3f3;

      padding-top: 8px;
      padding-bottom: 8px;

      font-size: 0.85rem;
      font-weight: 300;

      & > .text {
        font-size: 0.85rem;
        font-weight: 300;
      }

      & > .icon-label {
        margin-bottom: 5px;
        font-weight: 400;

        & > label {
          margin-left: 5px;
        }
      }
    }

    & > div.buttons {
      border-top: 1px solid #f3f3f3;
      padding-top: 18px;
      padding-bottom: 8px;

      display: flex;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 20px 10px;
  }
`;
