import React from 'react';
import styled from 'styled-components';
import tracker from 'rev.sdk.js/Utils/Tracker';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {mapLineBreak} from '../../../Utils/TextUtil';
import * as AppActions from '../../../AppActions';

export default function FundraisingProductRoot(props) {
  const {product} = props;
  const [article, setArticle] = React.useState(null);

  const fetchData = React.useCallback(async () => {
    function triggerTracker(product) {
      tracker.viewItem({
        name: 'product',
        item: product,
      });
    }

    try {
      triggerTracker(product);

      if (product.article) {
        setArticle(
          await JStorage.fetchOneDocument(
            'Article_Default',
            {
              id: product.article,
            },
            null,
            {anonymous: true},
          ),
        );
      }
    } catch (ex) {
      console.warn(ex);
    } finally {
      AppActions.setLoading(false);
    }
  }, [product]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      {article && (
        <div
          className="article-preview"
          dangerouslySetInnerHTML={{__html: article.html}}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .article-preview {
    white-space: pre;

    & img {
      max-width: 100%;
    }
  }
`;
